import React from "react";
import { Feature } from "../../components";
import "./whyus.css";

const WhyUs = () => {
  return (
    <div id="whyus" className="ets__whyus">
      <div className="ets__whyus_g2">
        <div className="ets__whyus-heading">
          <h1>Why Choose Us?</h1>
        </div>
        <div className="ets__whyus-para">
          <ul>
            <li>
              <p>
                <strong>Focus on Your Business</strong>: Let us handle the complexities of the supply chain while you concentrate on growing your business. We take care of everything from sourcing to delivery, reducing your operational burden.
              </p>
            </li>
            <li>
              <p>
                <strong>Local Market Expertise</strong>: With our deep understanding of the local market, we bring expertise in procuring high-quality products that meet your specific needs.
              </p>
            </li>
            <li>
              <p>
                <strong>Competitive Pricing</strong>: We negotiate the best prices for quality products, ensuring you receive the most competitive rates without compromising on quality.
              </p>
            </li>
            <li>
              <p>
                <strong>Risk Management</strong>: Our robust supply chain management reduces risks associated with international trade, giving you peace of mind and confidence in your supply chain.
              </p>
            </li>
            <li>
              <p>
                <strong>Quality Delivered</strong>: We are committed to delivering only the highest quality products, ensuring that every order arrives at your doorstep on time and as expected.
              </p>
            </li>
            <li>
              <p>
                <strong>Locally Available</strong>: Based in Australia, we offer import services tailored to local businesses. Our team is just a phone call away, ready to provide the support and solutions you need locally.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
