import React from "react";
import { Feature } from "../../components";
import "./contactus.css";
import call from "../../assets/call_2.png";
import email from "../../assets/email.png";

const ContactUs = () => {
  return (
    <div id="contactus" className="ets__contactus">
      <div className="ets__contactus_g1">
        <div className="ets__contactus-heading">
          <h1>Contact Us</h1>
        </div>
        <div className="ets__contactus-symbol">
          <img src={call} alt="call" /> 0499539349
        </div>
        <div className="ets__contactus-symbol">
          <img src={email} alt="email" /> sales@elevatetraders.com.au
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
