import React from "react";
import { Feature } from "../../components";
import "./tiles.css";

const Tiles = () => {
  return (
    <div id="tiles" className="ets__tiles">
      <div className="ets__tiles_g1">
        <div className="ets__tiles-heading">
          <h1>Tiles</h1>
        </div>
        <div className="ets__tiles-para">
          <p>
          As Elevate Traders we are your trusted B2B partner for importing premium tiles directly from Morbi, India—one of the world’s leading hubs for tile manufacturing. With our deep roots in Morbi, supported by a close-knit network of friends and family who are established manufacturers, we bring you unparalleled access to high-quality tiles at competitive prices.
          </p>
        </div>
        <div className="ets__tiles-para">
          <p>
          Morbi, India, is renowned globally for its tile manufacturing, producing a diverse range of tiles known for their durability, design, and affordability. The region is home to thousands of tile manufacturers, combining traditional craftsmanship with modern technology to create tiles that meet international standards.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Tiles;
