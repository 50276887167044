import React, { useState } from "react";
import "./App.css";

import {
  Footer,
  Header,
  Possibility,
  Features,
  WhatGPT3,
  AboutUs,
  ContactUs,
  Tiles,
  Blog,
  WhyUs,
} from "./containers";

import { Cta, Brand, Navbar } from "./components";

const App = () => {
  
  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar/>
      </div>
      <AboutUs />
      <Tiles />
      <WhyUs/>
      <ContactUs />
    </div>
  );
};

export default App;
