import React from "react";
import { Feature } from "../../components";
import "./aboutus.css";

const AboutUs = () => {
  return (
    <div id="aboutus" className="ets__abus">
      <div className="ets__abus_g1">
        <div className="ets__abus-heading">
          <h1>About us</h1>
        </div>
        <div className="ets__abus-para">
          <p>
            We are a young Australia-based B2B importer dedicated to bridging the gap between global manufacturers and local businesses. With a strong focus on quality, reliability, and customer service, we specialize in sourcing and importing high-quality products from around the world, ensuring that you can focus on what you do best—running your business.
          </p>
        </div>
        <div className="ets__abus-para">
          <p>
            At Elevate Traders, our mission is to empower Australian businesses by providing access to top-tier products and innovative solutions from trusted global partners.
            We strive to simplify the import process, reduce supply chain complexities, minimize supply chain risks, and ensure that our clients receive the best products at competitive prices.
          </p>
        </div>
        <div className="ets__abus-para">
          <p>
            We leverage our extensive network of international suppliers to bring you a diverse range ceremic products. Our team of experienced professionals is dedicated to ensuring every product we import meets stringent quality standards, offering you consistency and reliability with every order delivered right to your doorstep.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
